import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons"
import { Link } from "@reach/router"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import ChannelView from "../components/channel/view"
import Bookmarks from "../components/layout/bookmarks"
import ChannelFeeds from "../components/layout/channel-feeds"
import CompactChannelFeed from "../components/layout/compact-channel-feed"
import DirectMessageFeed from "../components/layout/direct-message-feed"
import DonationFilter from "../components/layout/donation-filter"
import DonationTypeFilter from "../components/layout/donation-type-filter"
import TagFilters from "../components/layout/tag-filters"
import Notice from "../components/widgets/notice"
import { useChannel } from "../hooks/use-channel"
import { useFilters } from "../hooks/use-filters"
import { useUser, getCachedSecret } from "../hooks/use-user"
import { convertChannels, convertComments } from "../services/content"
import { rpc_impl } from "../services/node"
import SortFilter from "../components/layout/sort-filter"
import Widget from "../../components/layout/widget"
import CommentModeration from "../components/comment/comment-moderation"
import { useComments } from "../hooks/use-comments"
import { Comment } from "../types"
import { useLoading } from "../hooks/use-loading"
import { useLazyLoading } from "../hooks/use-lazy-loading"
import { lightTheme, darkTheme } from "../services/theme"
import { useStyle } from "../hooks/use-style"
import ThreeColumnLayout from "../components/layout/three-column-layout"

const AllCommentsPage: FunctionComponent = () => {
  const { filters } = useFilters()
  const { user, setUser } = useUser()
  const [comments, setComments] = useState([])
  /*const {
    comments,
    setComments,
    upvoteComment,
    downvoteComment,
    publishComment,
    hideComment,
  } = useComments(target, [], true, parentComment)  */
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage")

      let url =
        /*process.env.API_URL +*/ "/api/v2/comments?count=40&offset=" +
        20 * offset +
        "&mod=" +
        filters.mod +
        "&sort=" +
        filters.sort +
        "&age=" +
        filters.age
      if (filters.donation_country != "any")
        url += "&country=" + filters.donation_country
      if (filters.donation_currency != "any")
        url += "&currency=" + filters.donation_currency
      if (filters.q != undefined && filters.q != "") url += "&q=" + filters.q
      if (user && user.subscriptions.length) {
        let ids = user.subscriptions.map(channel => channel.id)
        url += "&channel_ids=" + ids.join(",")
      }

      console.log(url)

      //if (filters.liked == false) {
      const response = await Promise.resolve(
        fetch(url)
          .then(res => res.json())
          .catch(error => {
            console.log("json parsing error occured ", error)
            return null
          })
      )
      if (response?.hits?.hits.length) {
        console.log(response)
        //let items = convertGateways(response)
        let items = convertComments(response, filters)
        //for (var i = 0; i < items.length; i++) items[i].amount = -1
        console.log(items)
        return items
      } else {
        console.log(response)
        return []
      }
      //} else return []
    },
    [filters]
  )

  useEffect(() => {}, [])

  const hideComment = useCallback(
    (commentId: string) => {
      //console.log("hideComment " + commentId)
      setComments(comments =>
        comments.filter(comment => comment.id !== commentId)
      )
    },
    [comments, setComments]
  )

  const add = useCallback(
    (newItems: Comment[], offset: number) => {
      if (newItems) {
        //console.log(feedId + " " + offset + " " + newItems)
        if (offset == 0 && comments.length > 0) {
          console.log("clearing items")
          setComments(newItems)
        } else setComments(comments => comments.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [comments]
  )

  useEffect(() => {
    //console.log("reloading")
    resetLoader()
  }, [filters])

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Comment>(comments, { fetchPage, add })

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ThreeColumnLayout>
        {{
          content: (
            <ul>
              {comments.map(comment => (
                <li key={comment.id} className="element">
                  <CommentModeration
                    comment={comment}
                    hideComment={hideComment}
                  />
                </li>
              ))}
            </ul>
          ),
          sidebar: (
            <>
              <SortFilter />
            </>
          ),
        }}
      </ThreeColumnLayout>
      <style jsx>{`
        .element {
          padding: 10px;
          background-color: ${css.backgroundColor};
          width: 100%;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li:not(:first-child) {
          margin-top: 1em;
        }
        li:not(:last-child) {
          margin-bottom: 1em;
        }
      `}</style>
    </>
  )
}
export default AllCommentsPage
