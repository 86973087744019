import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faReply,
} from "@fortawesome/pro-light-svg-icons"
import { faFlag } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { useAnimatedCallback } from "../../hooks/use-animated-callback"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { prettyDate, exactDate } from "../../services/date"
import { prettyAmount } from "../../services/number"
import { darkTheme, lightTheme } from "../../services/theme"
import { Comment, CommentTarget, LikeType } from "../../types"
import OptionsButton from "../layout/button/options"
import Description from "../layout/description"
import Panel from "../layout/panel"
import ModerationFee from "../moderation-fee"
import Flags from "../widgets/flags"
import CommentOptions from "./comment-options"
import Comments from "./comments"
import CommentInput from "./input"
import CommentLabel from "./label"

type Props = {
  comment: Comment
  hideComment: (commentId: string) => void
}

const CommentModeration: FunctionComponent<Props> = ({
  comment,
  hideComment,
}) => {
  const [showReplies, setShowReplies] = useState(false)
  const [edit, setEdit] = useState(false)

  const { user, commentLikes, accountState } = useUser()

  const loadReplies = useCallback(() => {
    setShowReplies(showReplies => !showReplies)
  }, [showReplies])

  const css = useStyle(theme => ({
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const onEdit = useCallback(() => {
    console.log("onEdit")
    setEdit(true)
  }, [setEdit])

  const onDelete = useCallback(() => {
    //publishComment(1, "", comment.id)
    setEdit(false)
  }, [setEdit, comment.id])

  return (
    <article>
      <div id={comment.id} className="hook"></div>

      <div className="main">
        <div className="layout">
          <Panel url="#">
            {{
              header: (
                <>
                  <div className="table">
                    <header className="table-cell">
                      <CommentLabel channel={comment.author} />
                    </header>
                    <div className="table-cell">
                      <div className="published">
                        {comment.edited}&nbsp;
                        {prettyDate(comment.published_at, true)}
                      </div>
                    </div>
                  </div>
                </>
              ),
              content: (
                <div className="description">
                  <Description linkify>{comment.text}</Description>
                </div>
              ),
              footer: (
                <div>
                  <div className="dt">
                    <div className="dr">
                      <div className="dc">
                        <div style={{ display: "inline-flex" }}>
                          <ModerationFee
                            id={"." + comment.id}
                            mf={comment.mf}
                            mfg={comment.mfg}
                            flag_stats={comment.flag_stats}
                            group={comment.target}
                            onMute={(id, kind) => hideComment(comment.id)}
                          />
                          {comment.comments_count > 0 && (
                            <div className="replies">
                              {comment.comments_count}
                              &nbsp;
                              {comment.comments_count === 1
                                ? "reply"
                                : "replies"}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="dc">
                        <div
                          style={{
                            float: "right",
                            paddingRight: "10px",
                          }}
                          className={
                            comment.score >= 0
                              ? "dc score-positive"
                              : "dc score-negative"
                          }
                        >
                          {comment.score}
                        </div>
                      </div>
                    </div>
                  </div>

                  {((comment.flags && comment.flags.length > 0) ||
                    comment.green_flags) && (
                    <div className="bottom">
                      <div></div>
                      <div>
                        <Flags
                          contentType={LikeType.Comment}
                          target={comment.target}
                          id={"." + comment.id}
                          flags={comment.flags}
                          flag_stats={comment.flag_stats}
                          green_flags={comment.green_flags}
                          red_flags={comment.red_flags}
                          yellow_flags={comment.yellow_flags}
                          style={{ display: "inline" }}
                        />
                      </div>
                      <div></div>
                    </div>
                  )}
                </div>
              ),
              upperToolbar: (
                <>
                  <div className="options">
                    <CommentOptions
                      comment={comment}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      hideComment={hideComment}
                    />{" "}
                  </div>
                </>
              ),
              lowerToolbar: null,
            }}
          </Panel>
        </div>
      </div>

      <style jsx>{`
      .bottom {
        justify-content: space-between;
        align-items: center;
        display: flex;
        min-width: 0;
        align-items: center;
        width: 100%;
      }

        .dt {
            display: table;
            width: 620px;
        }
        .dr {
            display: table-row;
        }
        .dc {
            display: table-cell;
        }
          .amount {
            font-size: 12px;
            font-weight: 500;
            font-family: Roboto;
            min-width: 40px;
            text-align: right;
            /*color: rgb(129, 129, 129);*/
          }

          @media (hover: hover) and (pointer: fine) {
            .options {
              visibility: hidden;
            }
            article:hover .options {
              visibility: visible;
            }
          }

  
          article {
            display: flex;
            border-radius: 0.3em;
            position: relative;
            align-items: flex-start;
          }
          .hook {
            position: absolute;
            top: -80px;
          }
          .main {
            flex: 1;
          }
          .layout {
            display: flex;
            overflow-wrap: break-word;
            justify-content: space-between;
            min-width: 0;
            /*border-top: 1px solid ${css.borderColor};*/
            padding-top: 1em;
            transition: 200ms border-color;
          }
          h1 {
            font-size: 0.9em;
            margin: 0.15em 0 0.15em;
          }
          .description {
            font-size: 0.8em;
            margin-top: 5px;
          }
          p {
            display: flex;
            font-size: 0.8em;
            color: ${css.infoColor};
            transition: 200ms color;
            margin: 0;
          }
          p:not(:last-child)::before {
            /*content: "•";
              margin-left: -0.5em;*/
            margin-left: 1em;
            padding-right: 0.5em;
          }
          .comments:not(:empty) {
            margin-top: 1em;
            margin-left: 2em;
          }
          .head {
            display: inline-flex;
          }
          .published {
            font-size: 0.8em;
            color: ${css.infoColor};
            margin-left: 1em;
            font-family: "Roboto";
          }
          .published::before {
            /*content: "•";*/
            margin-left: 1em;
            padding-right: 1em;
          }
          .replies {
            font-size: 14px;
            margin: auto;
            padding-top: 3px;
            padding-left: 10px;            
          }
          /*.replies:hover {
            cursor: pointer;
            text-decoration: underline;
          }*/
          .table {
            display: table;
          }
          .table-row {
            display: table-row;
          }
          .table-cell {
            display: table-cell;
            vertical-align: middle;
          }
          .vote {
            display: table-cell;
            vertical-align: middle;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 14px;
          }
          .vote:hover {
            cursor: pointer;
          }
          .score-positive {
            font-size: 12px;
            color: green;
            font-weight: 600;
          }
          .score-negative {
            font-size: 12px;
            color: red;
            font-weight: 600;
          }
        `}</style>
    </article>
  )
}

export default CommentModeration
